import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/ui-elements/ui-elements/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { View } from 'react-native';
import { CommentBox } from '@components/CommentBox';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "commentbox"
    }}>{`CommentBox`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={CommentBox} mdxType="Props" />
    <h2 {...{
      "id": "without-text"
    }}>{`Without Text`}</h2>
    <Playground __position={1} __code={'<CommentBox\n  authUser={{\n    id: \'id1\',\n    name: \'Marcel Popescu\',\n    profilePicture: \'https://picsum.photos/200\',\n    gender: {\n      name: \'male\',\n    },\n    isAdmin: false,\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      CommentBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <CommentBox authUser={{
        id: 'id1',
        name: 'Marcel Popescu',
        profilePicture: 'https://picsum.photos/200',
        gender: {
          name: 'male'
        },
        isAdmin: false
      }} mdxType="CommentBox" />
    </Playground>
    <h2 {...{
      "id": "with-text"
    }}>{`With Text`}</h2>
    <Playground __position={2} __code={'<CommentBox\n  authUser={{\n    id: \'id1\',\n    name: \'Marcel Popescu\',\n    profilePicture: \'https://picsum.photos/200\',\n    gender: {\n      name: \'male\',\n    },\n    isAdmin: false,\n  }}\n  value=\"This is a new comment\"\n  disabled={false}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      CommentBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <CommentBox authUser={{
        id: 'id1',
        name: 'Marcel Popescu',
        profilePicture: 'https://picsum.photos/200',
        gender: {
          name: 'male'
        },
        isAdmin: false
      }} value="This is a new comment" disabled={false} mdxType="CommentBox" />
    </Playground>
    <h2 {...{
      "id": "sending-data"
    }}>{`Sending Data`}</h2>
    <Playground __position={3} __code={'<CommentBox\n  authUser={{\n    id: \'id1\',\n    name: \'Marcel Popescu\',\n    profilePicture: \'https://picsum.photos/200\',\n    gender: {\n      name: \'male\',\n    },\n    isAdmin: false,\n  }}\n  value=\"This is a new comment\"\n  isSending\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      CommentBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <CommentBox authUser={{
        id: 'id1',
        name: 'Marcel Popescu',
        profilePicture: 'https://picsum.photos/200',
        gender: {
          name: 'male'
        },
        isAdmin: false
      }} value="This is a new comment" isSending mdxType="CommentBox" />
    </Playground>
    <h2 {...{
      "id": "with-multiline-text"
    }}>{`With Multiline Text`}</h2>
    <Playground __position={4} __code={'<CommentBox\n  authUser={{\n    id: \'id1\',\n    name: \'Marcel Popescu\',\n    profilePicture: \'https://picsum.photos/200\',\n    gender: {\n      name: \'male\',\n    },\n    isAdmin: false,\n  }}\n  value=\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet consectetur. Neque gravida in fermentum et sollicitudin ac orci phasellus. Suspendisse ultrices gravida dictum fusce ut placerat orci. Sit amet volutpat consequat mauris nunc. Donec adipiscing tristique risus nec feugiat in fermentum posuere urna. Sit amet tellus cras adipiscing enim eu turpis egestas pretium. Diam phasellus vestibulum lorem sed risus ultricies tristique nulla aliquet. Arcu dictum varius duis at consectetur lorem donec massa. Viverra suspendisse potenti nullam ac tortor. Integer quis auctor elit sed vulputate mi sit. Non nisi est sit amet facilisis magna etiam. Est velit egestas dui id ornare. Viverra suspendisse potenti nullam ac. Dolor purus non enim praesent. Varius duis at consectetur lorem donec massa sapien. Ultricies integer quis auctor elit sed vulputate mi.\"\n  disabled={false}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      CommentBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <CommentBox authUser={{
        id: 'id1',
        name: 'Marcel Popescu',
        profilePicture: 'https://picsum.photos/200',
        gender: {
          name: 'male'
        },
        isAdmin: false
      }} value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet consectetur. Neque gravida in fermentum et sollicitudin ac orci phasellus. Suspendisse ultrices gravida dictum fusce ut placerat orci. Sit amet volutpat consequat mauris nunc. Donec adipiscing tristique risus nec feugiat in fermentum posuere urna. Sit amet tellus cras adipiscing enim eu turpis egestas pretium. Diam phasellus vestibulum lorem sed risus ultricies tristique nulla aliquet. Arcu dictum varius duis at consectetur lorem donec massa. Viverra suspendisse potenti nullam ac tortor. Integer quis auctor elit sed vulputate mi sit. Non nisi est sit amet facilisis magna etiam. Est velit egestas dui id ornare. Viverra suspendisse potenti nullam ac. Dolor purus non enim praesent. Varius duis at consectetur lorem donec massa sapien. Ultricies integer quis auctor elit sed vulputate mi." disabled={false} mdxType="CommentBox" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      